import { useEffect, useState, useCallback } from 'react'
import { AbiItem } from 'web3-utils'
import { getWeb3NoAccount } from 'utils/web3'
import UniV2LPABI from 'constants/abis/UniV2LP.json'
import BigNumber from 'bignumber.js'
import useBlock from './useBlock'

const web3 = getWeb3NoAccount()
const AvaxUSDTPairAddress = '0x1F0bC5c91518D903c0c097BdE9741746B4423008'
const AvaxUsdtPairContract = new web3.eth.Contract(UniV2LPABI as unknown as AbiItem, AvaxUSDTPairAddress)

const HakuAvaxPairAddress = '0x7943Acd42c41a345841cB22Bd846794a22d8682d'
const HakuAvaxPairContract = new web3.eth.Contract(UniV2LPABI as unknown as AbiItem, HakuAvaxPairAddress)

const useHakuPrice = () => {
  const [price, setPrice] = useState(0)
  const block = useBlock()

  const fetchBalance = useCallback(async () => {
    try {
      const avaxObj = await AvaxUsdtPairContract.methods.getReserves().call()
      if (!new BigNumber(avaxObj._reserve0).eq(new BigNumber(0))) {
        const avaxPrice = new BigNumber(avaxObj._reserve1).div(avaxObj._reserve0).times(1e12)
        const hakuObj = await HakuAvaxPairContract.methods.getReserves().call()
        if (!new BigNumber(hakuObj._reserve0).eq(new BigNumber(0))) {
          const hakuPrice = new BigNumber(hakuObj._reserve1).div(hakuObj._reserve0).times(avaxPrice)
          if (!hakuPrice.isEqualTo(price)) {
            setPrice(hakuPrice.toNumber())
          }
        } else {
          setPrice(0.4)
        }
      } else {
        setPrice(0.4)
      }
    } catch (e) {
      setPrice(0)
    }
  }, [price])

  useEffect(() => {
    if (AvaxUsdtPairContract && HakuAvaxPairContract) {
      fetchBalance()
    }
  }, [setPrice, fetchBalance, block])

  return price
}

export default useHakuPrice
