import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useLpTokenFromSymbol } from './useContract'
import addresses from '../constants/token/pancakeswap.json'

const useGetApiPrices = () => {
  const [prices, setPrices] = useState({})
  const { account, chainId } = useWeb3React()
  const usdcAvaxContract: any = useLpTokenFromSymbol('usdc-avax')
  const hakuAvaxContract: any = useLpTokenFromSymbol('haku-avax')
  const usdtAvaxContract: any = useLpTokenFromSymbol('usdt-avax')
  const ethAvaxContract: any = useLpTokenFromSymbol('eth-avax')
  const btcAvaxContract: any = useLpTokenFromSymbol('btc-avax')
  const ethBtcContract: any = useLpTokenFromSymbol('eth-btc')
  const ethUsdcContract: any = useLpTokenFromSymbol('eth-usdc')
  const ethUsdtContract: any = useLpTokenFromSymbol('eth-usdt')
  const btcUsdcContract: any = useLpTokenFromSymbol('btc-usdc')
  const btcUsdtContract: any = useLpTokenFromSymbol('btc-usdt')
  const usdtUsdcContract: any = useLpTokenFromSymbol('usdt-usdc')
  const avaxDaiContract: any = useLpTokenFromSymbol('avax-dai')
  const avaxLinkContract: any = useLpTokenFromSymbol('avax-link')
  const mimWavaxContract: any = useLpTokenFromSymbol('mim-wavax')

  const getLpPrice = async (contract, baseTokenSymbol, price = 1) => {
    let lpPrice = new BigNumber(0)
    const token0 = await contract.token0()
    const result = await contract.getReserves()
    const totalSupply = await contract.totalSupply()
    const address = await addresses.tokens
      .find((t) => t.symbol.toLowerCase().includes(baseTokenSymbol.toLowerCase()))
      ?.address.toLowerCase()
    
    if (token0.toLowerCase() === address) {
      lpPrice = new BigNumber(result._reserve0._hex).times(price).times(2).div(new BigNumber(totalSupply._hex))
    } else {
      lpPrice = new BigNumber(result._reserve1._hex).times(price).times(2).div(new BigNumber(totalSupply._hex))
    }
    if ((baseTokenSymbol === 'usdc' || baseTokenSymbol === 'usdt') && baseTokenSymbol !== 'dai') {
      lpPrice = lpPrice.times(10 ** 12)
    }
    if (baseTokenSymbol === 'btc') {
      lpPrice = lpPrice.times(10 ** 2)
    }
    return lpPrice.toString()
  }

  const getEthPrice = async () => {
    let usdcReserve: any = 0
    let ethReserve: any = 0
    let price: any = 0

    const token0 = await ethUsdcContract.token0()
    const result = await ethUsdcContract.getReserves()

    if (token0 === '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664') {
      usdcReserve = result._reserve0.toString()
      ethReserve = result._reserve1.toString()
    } else {
      usdcReserve = result._reserve1.toString()
      ethReserve = result._reserve0.toString()
    }
    price = new BigNumber(usdcReserve)
      .div(ethReserve)
      .times(10 ** 12)
      .toString()
    return price
  }

  const getBtcPrice = async () => {
    let usdcReserve: any = 0
    let btcReserve: any = 0
    let price: any = 0

    const token0 = await btcUsdcContract.token0()
    const result = await btcUsdcContract.getReserves()

    if (token0 === '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664') {
      usdcReserve = result._reserve0.toString()
      btcReserve = result._reserve1.toString()
    } else {
      usdcReserve = result._reserve1.toString()
      btcReserve = result._reserve0.toString()
    }
    price = new BigNumber(usdcReserve)
      .div(btcReserve)
      .times(10 ** 2)
      .toString()
    return price
  }

  const getPrices = async () => {
    let usdcReserve: any = 0
    let avaxReserve: any = 0
    let price: any = 0

    const token0 = await usdcAvaxContract.token0()
    const result = await usdcAvaxContract.getReserves()

    if (token0 === '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664') {
      usdcReserve = result._reserve0.toString()
      avaxReserve = result._reserve1.toString()
    } else {
      usdcReserve = result._reserve1.toString()
      avaxReserve = result._reserve0.toString()
    }
    price = new BigNumber(usdcReserve)
      .div(avaxReserve)
      .times(10 ** 12)
      .toString()
    const ethPrice = await getEthPrice()
    const btcPrice = await getBtcPrice()

    const usdcAvaxPrice = await getLpPrice(usdcAvaxContract, 'WAVAX', price)
    const hakuAvaxPrice = await getLpPrice(hakuAvaxContract, 'WAVAX', price)
    const usdtAvaxPrice = await getLpPrice(usdtAvaxContract, 'WAVAX', price)
    const ethAvaxPrice = await getLpPrice(ethAvaxContract, 'WAVAX', price)
    const btcAvaxPrice = await getLpPrice(btcAvaxContract, 'WAVAX', price)
    const ethBtcPrice = await getLpPrice(ethBtcContract, 'eth', ethPrice)
    const ethUsdcPrice = await getLpPrice(ethUsdcContract, 'eth', ethPrice)
    const ethUsdtPrice = await getLpPrice(ethUsdtContract, 'eth', ethPrice)
    const btcUsdcPrice = await getLpPrice(btcUsdcContract, 'btc', btcPrice)
    const btcUsdtPrice = await getLpPrice(btcUsdtContract, 'btc', btcPrice)
    const usdtUsdcPrice = await getLpPrice(usdtUsdcContract, 'usdc', 1)
    const avaxDaiPrice = await getLpPrice(avaxDaiContract, 'WAVAX', price)
    const avaxLinkPrice = await getLpPrice(avaxLinkContract, 'WAVAX', price)
    const mimWavaxPrice = await getLpPrice(mimWavaxContract, 'WAVAX', price)

    setPrices({
      wavax: price,
      'usdc-avax': usdcAvaxPrice,
      'haku-avax': hakuAvaxPrice,
      'usdt-avax': usdtAvaxPrice,
      'eth-avax': ethAvaxPrice,
      'btc-avax': btcAvaxPrice,
      'eth-btc': ethBtcPrice,
      'eth-usdc': ethUsdcPrice,
      'eth-usdt': ethUsdtPrice,
      'btc-usdc': btcUsdcPrice,
      'btc-usdt': btcUsdtPrice,
      'usdt-usdc': usdtUsdcPrice,
      'avax-dai': avaxDaiPrice,
      'avax-link': avaxLinkPrice,
      'mim-wavax': mimWavaxPrice
      // eth: ethPrice
    })
  }

  useEffect(() => {
    if (account) {
      getPrices()
    }
    const interval = setInterval(async () => {
      if (account) {
        getPrices()
      }
    }, 10 * 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])

  return prices
}

export default useGetApiPrices
