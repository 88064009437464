import { MenuEntry } from 'hakuswap-uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://hakuswap.com',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
      {
        label: 'Zap',
        href: '/zap',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://hakuswap.com/farms',
  },
  {
    label: 'Staking',
    icon: 'PoolIcon',
    href: 'https://hakuswap.com/xhaku',
  },
  {
    label: 'Bridge',
    icon: 'BridgeIcon',
    href: 'https://hakuswap.com/bridge',
    tag: 'new',
  },
  {
    label: 'NFT Marketplace',
    icon: 'NftIcon',
    href: '/marketplace',
  },
  {
    label: 'Mines of Haku',
    icon: 'VaultIcon',
    href: 'https://minesofhaku.com',
  },
  {
    label: 'The Battle of Haku',
    icon: 'LotteryIcon',
    href: 'https://battle.hakuswap.com/stake',
  },
  {
    label: 'Token Sales',
    icon: 'IfoIcon',
    href: 'https://hakuswap.com/iho',
  },
  // {
  //   label: 'Pforce',
  //   icon: 'PforceIcon',
  //   href: 'https://hakuswap.com/pforce',
  // },
  {
    label: 'Info',
    icon: 'InfoIcon',
    href: 'https://info.hakuswap.com/home',
  },
  {
    label: 'Multisender',
    icon: 'DisperseIcon',
    href: 'https://hakuswap.com/disperse',
  },
  {
    label: 'Partnership',
    icon: 'PartnerIcon',
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSePw9y_LsviBJTPSMH2rTXfMb4LD7c0TSdtwY1KHZRTgXdJJw/viewform',
  },
  {
    label: 'Audit',
    icon: 'AuditIcon',
    href: 'https://docs.hakuswap.com/audits',
  },
  // {
  //   label: 'Listing',
  //   icon: 'ReferralIcon',
  //   items: [
  //     {
  //       label: 'Snowtrace Explorer',
  //       href: 'https://snowtrace.io/token/0x695Fa794d59106cEbd40ab5f5cA19F458c723829',
  //       target: '_blank',
  //     },
  //     {
  //       label: 'DappRadar',
  //       href: '#',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: '#',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: '#',
  //     },
  //   ],
  // },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://docs.hakuswap.com',
        target: '_blank',
      },
      {
        label: 'Blog',
        href: 'https://hakuswap.medium.com/',
        target: '_blank',
      },
    ],
  },
]

export default config
